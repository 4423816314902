<template>
  <div>
    <HeaderNavigation></HeaderNavigation>
    <TitleBlok></TitleBlok>
    <div class="lg:max-w-7xl mx-auto pt-6">
      <img src="@/assets/logo.svg" />
    </div>

    <h1 class="leading-tight text-9xl mt-0 mb-2">Coming soon</h1>

    <!-- <CardTeaserContainer></CardTeaserContainer> -->
  </div>
  <FooterNavigation></FooterNavigation>
</template>

<script>
// @ is an alias to /src
import HeaderNavigation from "@/components/HeaderNavigation";
import FooterNavigation from "@/components/FooterNavigation";
// import productOverview from "@/components/productComponents/Overview/indexOverview.vue";
// import CardTeaserContainer from "@/components/CardTeaserContainer";
import TitleBlok from "@/components/TitleBlok";

export default {
  name: "HomeView",
  components: {
    HeaderNavigation,
    FooterNavigation,
    // CardTeaserContainer,
    TitleBlok,
  },
};
</script>
