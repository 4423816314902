import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./css/index.css";
import { StoryblokVue, apiPlugin } from "@storyblok/vue";

createApp(App)
  .use(store)
  .use(router)
  .use(StoryblokVue, {
    accessToken: "BDl2DAHXeKKrV3eaigYoYAtt",
    use: [apiPlugin],
  })
  .mount("#app");
