<template>
  <div>
    <p v-for="story in data" :key="story.id">{{ story.name }}</p>
  </div>
</template>

<script setup>
import { useStoryblokApi } from "@storyblok/vue";

const storyblokApi = useStoryblokApi();
const { data } = await storyblokApi.get("cdn/stories", { version: "draft" });
console.log(data);
</script>
